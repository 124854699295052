import { useEffect, useState } from "react";
import { api as prodApi, demoApi } from "./wpInfoApi";
import { Domain, WordPressInfo } from "../lib/cnb/api/public";

const api = process.env.NODE_ENV === "development" ? demoApi : prodApi;
const useWpInfo = (currentDomain: Domain | undefined) => {
  const [wpInfo, setWpInfo] = useState<WordPressInfo | undefined>(undefined);

  useEffect(() => {
    if (!currentDomain) {
      setWpInfo(undefined);
      return;
    }
    api
      .getWpInfo(currentDomain)
      .then((wpInfo) => setWpInfo(wpInfo))
      .catch(() => {}); // NOOP
  }, [currentDomain]);

  const getFormattedAmount = () => {
    const number = wpInfo?.subscriptionStatusData?.invoiceAmount;
    if (!number) return "";

    const amount = number / 100.0;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: wpInfo.subscriptionStatusData?.invoiceCurrency,
    }).format(amount);
  };

  const hasOutstandingInvoice = () => {
    return !!wpInfo?.subscriptionStatusData?.invoiceUrl;
  };
  return {
    wpInfo: wpInfo,
    getFormattedAmount: getFormattedAmount,
    hasOutstandingInvoice: hasOutstandingInvoice,
  };
};

export default useWpInfo;
