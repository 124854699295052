/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  Button,
  ButtonCreateRequest,
  ButtonCreateRequestV2,
  ButtonMinimal,
  ButtonUpdateRequest,
  ButtonUpdateRequestV2,
  CopyButtonRequest,
  DeleteButtonResponse,
  ExportButtonRequest,
  ExportButtonResponse,
  ImportButtonRequest,
  ValidationResult,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    ButtonFromJSON,
    ButtonToJSON,
    ButtonCreateRequestFromJSON,
    ButtonCreateRequestToJSON,
    ButtonCreateRequestV2FromJSON,
    ButtonCreateRequestV2ToJSON,
    ButtonMinimalFromJSON,
    ButtonMinimalToJSON,
    ButtonUpdateRequestFromJSON,
    ButtonUpdateRequestToJSON,
    ButtonUpdateRequestV2FromJSON,
    ButtonUpdateRequestV2ToJSON,
    CopyButtonRequestFromJSON,
    CopyButtonRequestToJSON,
    DeleteButtonResponseFromJSON,
    DeleteButtonResponseToJSON,
    ExportButtonRequestFromJSON,
    ExportButtonRequestToJSON,
    ExportButtonResponseFromJSON,
    ExportButtonResponseToJSON,
    ImportButtonRequestFromJSON,
    ImportButtonRequestToJSON,
    ValidationResultFromJSON,
    ValidationResultToJSON,
} from '../models/index';

export interface ButtonApiExportRequest {
    exportButtonRequest: ExportButtonRequest;
}

export interface ButtonApiImportRequest {
    importButtonRequest: ImportButtonRequest;
}

export interface ButtonApiCopyRequest {
    copyButtonRequest: CopyButtonRequest;
}

export interface ButtonApiCreateRequest {
    buttonCreateRequest: ButtonCreateRequest;
}

export interface ButtonApiCreateFullRequest {
    buttonCreateRequest: ButtonCreateRequest;
}

export interface ButtonApiCreateV2Request {
    buttonCreateRequestV2: ButtonCreateRequestV2;
}

export interface ButtonApiDeleteRequest {
    buttonId: string;
}

export interface ButtonApiGetRequest {
    buttonId: string;
}

export interface ButtonApiGetAllForDomainRequest {
    domainId: string;
}

export interface ButtonApiGetFullRequest {
    buttonId: string;
}

export interface ButtonApiGetValidationErrorsRequest {
    buttonId: string;
}

export interface ButtonApiUpdateRequest {
    buttonId: string;
    buttonUpdateRequest: ButtonUpdateRequest;
}

export interface ButtonApiUpdateFullRequest {
    buttonId: string;
    buttonUpdateRequest: ButtonUpdateRequest;
}

export interface ButtonApiUpdateFullV2Request {
    buttonId: string;
    buttonUpdateRequestV2: ButtonUpdateRequestV2;
}

/**
 * 
 */
export class ButtonApi extends runtime.BaseAPI {

    /**
     * Export buttons (and optionally the domain Properties)
     */
    async _exportRaw(requestParameters: ButtonApiExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportButtonResponse>> {
        if (requestParameters['exportButtonRequest'] == null) {
            throw new runtime.RequiredError(
                'exportButtonRequest',
                'Required parameter "exportButtonRequest" was null or undefined when calling _export().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportButtonRequestToJSON(requestParameters['exportButtonRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportButtonResponseFromJSON(jsonValue));
    }

    /**
     * Export buttons (and optionally the domain Properties)
     */
    async _export(requestParameters: ButtonApiExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportButtonResponse> {
        const response = await this._exportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import buttons (and optionally the domain Properties)
     */
    async _importRaw(requestParameters: ButtonApiImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Button>>> {
        if (requestParameters['importButtonRequest'] == null) {
            throw new runtime.RequiredError(
                'importButtonRequest',
                'Required parameter "importButtonRequest" was null or undefined when calling _import().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportButtonRequestToJSON(requestParameters['importButtonRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ButtonFromJSON));
    }

    /**
     * Import buttons (and optionally the domain Properties)
     */
    async _import(requestParameters: ButtonApiImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Button>> {
        const response = await this._importRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Copy buttons from source domain to target (and optionally the domain Properties) within the same Account
     */
    async copyRaw(requestParameters: ButtonApiCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Button>>> {
        if (requestParameters['copyButtonRequest'] == null) {
            throw new runtime.RequiredError(
                'copyButtonRequest',
                'Required parameter "copyButtonRequest" was null or undefined when calling copy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyButtonRequestToJSON(requestParameters['copyButtonRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ButtonFromJSON));
    }

    /**
     * Copy buttons from source domain to target (and optionally the domain Properties) within the same Account
     */
    async copy(requestParameters: ButtonApiCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Button>> {
        const response = await this.copyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a `Button`
     * @deprecated
     */
    async createRaw(requestParameters: ButtonApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ButtonMinimal>> {
        if (requestParameters['buttonCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'buttonCreateRequest',
                'Required parameter "buttonCreateRequest" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ButtonCreateRequestToJSON(requestParameters['buttonCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonMinimalFromJSON(jsonValue));
    }

    /**
     * Create a `Button`
     * @deprecated
     */
    async create(requestParameters: ButtonApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ButtonMinimal> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a `Button` and return a Full `Button`
     * @deprecated
     */
    async createFullRaw(requestParameters: ButtonApiCreateFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Button>> {
        if (requestParameters['buttonCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'buttonCreateRequest',
                'Required parameter "buttonCreateRequest" was null or undefined when calling createFull().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/full`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ButtonCreateRequestToJSON(requestParameters['buttonCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonFromJSON(jsonValue));
    }

    /**
     * Create a `Button` and return a Full `Button`
     * @deprecated
     */
    async createFull(requestParameters: ButtonApiCreateFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Button> {
        const response = await this.createFullRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a `Button` and return a Full `Button`
     */
    async createV2Raw(requestParameters: ButtonApiCreateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Button>> {
        if (requestParameters['buttonCreateRequestV2'] == null) {
            throw new runtime.RequiredError(
                'buttonCreateRequestV2',
                'Required parameter "buttonCreateRequestV2" was null or undefined when calling createV2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/full-v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ButtonCreateRequestV2ToJSON(requestParameters['buttonCreateRequestV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonFromJSON(jsonValue));
    }

    /**
     * Create a `Button` and return a Full `Button`
     */
    async createV2(requestParameters: ButtonApiCreateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Button> {
        const response = await this.createV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a `Button`
     */
    async deleteRaw(requestParameters: ButtonApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteButtonResponse>> {
        if (requestParameters['buttonId'] == null) {
            throw new runtime.RequiredError(
                'buttonId',
                'Required parameter "buttonId" was null or undefined when calling delete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/{buttonId}`.replace(`{${"buttonId"}}`, encodeURIComponent(String(requestParameters['buttonId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteButtonResponseFromJSON(jsonValue));
    }

    /**
     * Delete a `Button`
     */
    async delete(requestParameters: ButtonApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteButtonResponse> {
        const response = await this.deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single `Button`
     */
    async getRaw(requestParameters: ButtonApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ButtonMinimal>> {
        if (requestParameters['buttonId'] == null) {
            throw new runtime.RequiredError(
                'buttonId',
                'Required parameter "buttonId" was null or undefined when calling get().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/{buttonId}`.replace(`{${"buttonId"}}`, encodeURIComponent(String(requestParameters['buttonId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonMinimalFromJSON(jsonValue));
    }

    /**
     * Get a single `Button`
     */
    async get(requestParameters: ButtonApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ButtonMinimal> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all buttons.
     */
    async getAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ButtonMinimal>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ButtonMinimalFromJSON));
    }

    /**
     * Get all buttons.
     */
    async getAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ButtonMinimal>> {
        const response = await this.getAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all `Button`s for a single `Domain`.
     */
    async getAllForDomainRaw(requestParameters: ButtonApiGetAllForDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ButtonMinimal>>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling getAllForDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/byDomainId/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ButtonMinimalFromJSON));
    }

    /**
     * Get all `Button`s for a single `Domain`.
     */
    async getAllForDomain(requestParameters: ButtonApiGetAllForDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ButtonMinimal>> {
        const response = await this.getAllForDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all \'full\' Buttons (meaning they have the `Domain` serialized).
     */
    async getAllFullRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Button>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/full`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ButtonFromJSON));
    }

    /**
     * Get all \'full\' Buttons (meaning they have the `Domain` serialized).
     */
    async getAllFull(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Button>> {
        const response = await this.getAllFullRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all validation errors for all Buttons.
     */
    async getAllValidationErrorsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ValidationResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/validation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ValidationResultFromJSON));
    }

    /**
     * Get all validation errors for all Buttons.
     */
    async getAllValidationErrors(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ValidationResult>> {
        const response = await this.getAllValidationErrorsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a single Full `Button`
     */
    async getFullRaw(requestParameters: ButtonApiGetFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Button>> {
        if (requestParameters['buttonId'] == null) {
            throw new runtime.RequiredError(
                'buttonId',
                'Required parameter "buttonId" was null or undefined when calling getFull().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/{buttonId}/full`.replace(`{${"buttonId"}}`, encodeURIComponent(String(requestParameters['buttonId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonFromJSON(jsonValue));
    }

    /**
     * Get a single Full `Button`
     */
    async getFull(requestParameters: ButtonApiGetFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Button> {
        const response = await this.getFullRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all validation errors for this Buttons.
     */
    async getValidationErrorsRaw(requestParameters: ButtonApiGetValidationErrorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ValidationResult>>> {
        if (requestParameters['buttonId'] == null) {
            throw new runtime.RequiredError(
                'buttonId',
                'Required parameter "buttonId" was null or undefined when calling getValidationErrors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/{buttonId}/validation`.replace(`{${"buttonId"}}`, encodeURIComponent(String(requestParameters['buttonId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ValidationResultFromJSON));
    }

    /**
     * Get all validation errors for this Buttons.
     */
    async getValidationErrors(requestParameters: ButtonApiGetValidationErrorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ValidationResult>> {
        const response = await this.getValidationErrorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a `Button`
     * @deprecated
     */
    async updateRaw(requestParameters: ButtonApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ButtonMinimal>> {
        if (requestParameters['buttonId'] == null) {
            throw new runtime.RequiredError(
                'buttonId',
                'Required parameter "buttonId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['buttonUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'buttonUpdateRequest',
                'Required parameter "buttonUpdateRequest" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/{buttonId}`.replace(`{${"buttonId"}}`, encodeURIComponent(String(requestParameters['buttonId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ButtonUpdateRequestToJSON(requestParameters['buttonUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonMinimalFromJSON(jsonValue));
    }

    /**
     * Update a `Button`
     * @deprecated
     */
    async update(requestParameters: ButtonApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ButtonMinimal> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a `Button` and return a Full `Button`
     * @deprecated
     */
    async updateFullRaw(requestParameters: ButtonApiUpdateFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Button>> {
        if (requestParameters['buttonId'] == null) {
            throw new runtime.RequiredError(
                'buttonId',
                'Required parameter "buttonId" was null or undefined when calling updateFull().'
            );
        }

        if (requestParameters['buttonUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'buttonUpdateRequest',
                'Required parameter "buttonUpdateRequest" was null or undefined when calling updateFull().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/{buttonId}/full`.replace(`{${"buttonId"}}`, encodeURIComponent(String(requestParameters['buttonId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ButtonUpdateRequestToJSON(requestParameters['buttonUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonFromJSON(jsonValue));
    }

    /**
     * Update a `Button` and return a Full `Button`
     * @deprecated
     */
    async updateFull(requestParameters: ButtonApiUpdateFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Button> {
        const response = await this.updateFullRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a `Button` and return a Full `Button`
     */
    async updateFullV2Raw(requestParameters: ButtonApiUpdateFullV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Button>> {
        if (requestParameters['buttonId'] == null) {
            throw new runtime.RequiredError(
                'buttonId',
                'Required parameter "buttonId" was null or undefined when calling updateFullV2().'
            );
        }

        if (requestParameters['buttonUpdateRequestV2'] == null) {
            throw new runtime.RequiredError(
                'buttonUpdateRequestV2',
                'Required parameter "buttonUpdateRequestV2" was null or undefined when calling updateFullV2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/button/{buttonId}/full-v2`.replace(`{${"buttonId"}}`, encodeURIComponent(String(requestParameters['buttonId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ButtonUpdateRequestV2ToJSON(requestParameters['buttonUpdateRequestV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonFromJSON(jsonValue));
    }

    /**
     * Update a `Button` and return a Full `Button`
     */
    async updateFullV2(requestParameters: ButtonApiUpdateFullV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Button> {
        const response = await this.updateFullV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
