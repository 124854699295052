/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  PromotionCode,
  StripeBillingPortal,
  StripeBillingPortalConfiguration,
  StripePlan,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    PromotionCodeFromJSON,
    PromotionCodeToJSON,
    StripeBillingPortalFromJSON,
    StripeBillingPortalToJSON,
    StripeBillingPortalConfigurationFromJSON,
    StripeBillingPortalConfigurationToJSON,
    StripePlanFromJSON,
    StripePlanToJSON,
} from '../models/index';

export interface StripeApiCreateBillingPortalRequest {
    stripeBillingPortalConfiguration?: StripeBillingPortalConfiguration;
}

/**
 * 
 */
export class StripeApi extends runtime.BaseAPI {

    /**
     * Create a Stripe Billing Portal
     */
    async createBillingPortalRaw(requestParameters: StripeApiCreateBillingPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StripeBillingPortal>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/stripe/createBillingPortal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StripeBillingPortalConfigurationToJSON(requestParameters['stripeBillingPortalConfiguration']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StripeBillingPortalFromJSON(jsonValue));
    }

    /**
     * Create a Stripe Billing Portal
     */
    async createBillingPortal(requestParameters: StripeApiCreateBillingPortalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StripeBillingPortal> {
        const response = await this.createBillingPortalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all available plans
     */
    async getAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StripePlan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/stripe/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StripePlanFromJSON));
    }

    /**
     * Get all available plans
     */
    async getAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StripePlan>> {
        const response = await this.getAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the currently active/available Coupon
     */
    async getCouponRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PromotionCode>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/stripe/coupons/wp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PromotionCodeFromJSON(jsonValue));
    }

    /**
     * Get the currently active/available Coupon
     */
    async getCoupon(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PromotionCode> {
        const response = await this.getCouponRaw(initOverrides);
        return await response.value();
    }

}
