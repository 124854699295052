import { WithId, routes } from "../common";
import { domainApi, stripeApi, subscriptionApi } from "../cnb/apis";
import { Domain, type StripePlan } from "../lib/cnb/api/public";

const api = {
  getDomains: async (): Promise<Domain[]> => domainApi.getAll(),

  createDomain: async (domain: Partial<Domain>): Promise<Domain> =>
    domainApi.create({
      domainCreateRequest: domain,
    }),

  updateDomain: async (domain: Domain): Promise<Domain> =>
    domainApi.update({ domainId: domain.id, domainUpdateRequest: domain }),

  deleteDomain: async (id: string) => domainApi.delete({ domainId: id }),

  getCurrentDomainId: () => localStorage.getItem("currentDomain"),

  setCurrentDomainId: (domainId: string) =>
    localStorage.setItem("currentDomain", domainId),

  getStripePlans: async (): Promise<StripePlan[]> => stripeApi.getAll(),

  redirectToCheckout: async (planId: string, domainId: string) => {
    const { url } = await subscriptionApi.createCheckoutSession({
      checkoutSessionCreateRequest: {
        plan: planId,
        domain: domainId,
        callbackUri: new URL(routes.DOMAIN_EDIT.path, window.location.href)
          .href,
      },
    });
    window.location.href = url;
  },

  getPaymentSession: async (
    checkoutSessionId: string,
  ): Promise<
    | { subscriptionId: undefined }
    | {
        subscriptionId: string;
        domain: string;
      }
  > => subscriptionApi.getCheckoutSession({ checkoutSessionId }),
};

export default api;

const demoGetDomains = <D>(): WithId<D>[] =>
  JSON.parse(localStorage.getItem("domains") || "[]");

const demoSetDomains = (domains: unknown[]) => {
  localStorage.setItem("domains", JSON.stringify(domains));
};

const demoStripePlans = [
  {
    id: "customUserPlan",
    nickname: "custom-user-plan",
    domainType: "PRO",
    currency: "EUR",
    interval: "MONTHLY",
    price: 1.99,
  },
  {
    id: "EURyearly",
    nickname: "powered-by-eur-yearly",
    domainType: "PRO",
    currency: "EUR",
    interval: "YEARLY",
    price: 19.99,
  },
  {
    id: "EURmonthly",
    nickname: "powered-by-eur-monthly",
    domainType: "PRO",
    currency: "EUR",
    interval: "MONTHLY",
    price: 4.99,
  },
  {
    id: "USDyearly",
    nickname: "powered-by-usd-yearly",
    domainType: "PRO",
    currency: "USD",
    interval: "YEARLY",
    price: 23.88,
  },
  {
    id: "USDmonthly",
    nickname: "powered-by-usd-monthly",
    domainType: "PRO",
    currency: "USD",
    interval: "MONTHLY",
    price: 5.99,
  },
];

const demoPaymentResults: Record<string, number> = {};

export const demoApi: typeof api = {
  getDomains: <D>() => Promise.resolve(demoGetDomains<D>()),

  createDomain: <D>(domain: Partial<D>) => {
    const domains = demoGetDomains<D>();
    if (domains.length >= 10) {
      throw new Error("You have exceeded the domain limit of 10 per account");
    }
    domains.push({
      id: String(Date.now()),
      name: "",
      type: "STARTER",
      timezone: "",
      trackGA: false,
      trackConversion: false,
      properties: {
        scale: "1.0",
        zindex: "2147483647",
        debug: "false",
        allowMultipleButtons: "true",
      },
      ...domain,
    } as any);
    demoSetDomains(domains);
    return Promise.resolve(domains[domains.length - 1]);
  },

  updateDomain: <D>(domain: WithId<D>) => {
    const domains = demoGetDomains<D>();
    domains[domains.findIndex((d) => d.id === domain.id)] = domain;
    demoSetDomains(domains);
    return Promise.resolve(domain);
  },

  deleteDomain: (id: string) => {
    const domains = demoGetDomains<any>();
    const domain = domains.find((d) => d.id === id);
    demoSetDomains(domains.filter((d) => d.id !== id));
    return Promise.resolve({
      id: domain.id,
      messages: [],
      object: domain,
      success: true,
    });
  },

  getCurrentDomainId: api.getCurrentDomainId,

  setCurrentDomainId: api.setCurrentDomainId,

  getStripePlans: () => Promise.resolve<any[]>(demoStripePlans),

  redirectToCheckout: (planId, domainId) => {
    window.location.href = `${window.location.origin}${
      routes.DOMAIN_EDIT.path
    }?payment=success&checkout_session_id=${planId}_${domainId}_${Date.now()}`;
    return Promise.reject("You should not see this");
  },

  getPaymentSession: (checkoutSessionId: string) => {
    const fetchNum = demoPaymentResults[checkoutSessionId] || 0;
    demoPaymentResults[checkoutSessionId] = fetchNum + 1;
    if (fetchNum <= 1) {
      return Promise.resolve({ subscriptionId: undefined });
    }

    const [, domainId] = checkoutSessionId.split("_");
    return Promise.resolve({
      domain: domainId,
      subscriptionId: `sub_${checkoutSessionId}`,
    });
  },
};
