import { createContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { default as prodApi, demoApi } from "./authApi";
import {
  Apikey,
  SetStorageSettingsImplementationEnum,
  User,
} from "../lib/cnb/api/public";

const api = process.env.NODE_ENV === "development" ? demoApi : prodApi;

const SESSION_FETCH_INTERVAL =
  process.env.NODE_ENV === "development" ? 0 : 60 * 1000;

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [apikeys, setApikeys] = useState<Array<Apikey> | undefined>(undefined);
  const [storageSolution, setStorageSolution] = useState<
    SetStorageSettingsImplementationEnum | undefined
  >();

  const getCurrentUserOrNull = async () => {
    try {
      return await api.getCurrentUser();
    } catch (e) {
      return null;
    }
  };

  const initCurrentUser = async (user?: User | null) => {
    const cu = user !== undefined ? user : await getCurrentUserOrNull();
    setCurrentUser(cu);
    cu && setApikeys(await api.getApikeys());
    if (SESSION_FETCH_INTERVAL) {
      cu && setTimeout(initCurrentUser, SESSION_FETCH_INTERVAL);
    }
    Sentry.setUser(
      cu && {
        id: cu.id,
        email: cu.email,
        username: cu.name || "",
      },
    );
  };

  useEffect(() => {
    initCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const register = (
    email: string,
    password: string,
    emailOptIn: boolean,
    signupSource: string,
  ) => api.register(email, password, emailOptIn, signupSource);

  const login = async (email: string, password: string, remember: boolean) =>
    initCurrentUser(await api.login(email, password, remember));

  const logout = async () => setCurrentUser(await api.logout());

  const updateUser = async (user: Partial<User>) =>
    setCurrentUser(await api.updateUser(user));

  const updatePassword = (currentPassword: string, newPassword: string) =>
    api.updatePassword(currentPassword, newPassword);

  const requestPasswordReset = (email: string) =>
    api.requestPasswordReset(email);

  const resetPassword = (token: string, password: string) =>
    api.resetPassword(token, password);

  const createApikey = async (name: string) => {
    const newApikey = await api.createApikey(name);
    setApikeys((ks) => (ks || []).concat(newApikey));
    return newApikey;
  };

  const deleteApikey = async (id: string) => {
    await api.deleteApikey(id);
    setApikeys((ks) => ks?.filter((k) => k.id !== id));
  };

  const setStorageImplementation = async (
    storageImplementation: SetStorageSettingsImplementationEnum,
  ) => {
    await api.setStorageImplementation(storageImplementation);
    setStorageSolution(storageSolution);
  };

  const openPaymentHistory = () => api.openPaymentHistory();

  return useMemo(
    () => ({
      loggedIn: !!currentUser,
      currentUser: currentUser as User,
      apikeys,
      register,
      login,
      logout,
      updateUser,
      updatePassword,
      requestPasswordReset,
      resetPassword,
      createApikey,
      deleteApikey,
      openPaymentHistory,
      setStorageImplementation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, apikeys, storageSolution],
  );
};

export const AuthContext = createContext<ReturnType<typeof useAuth>>(
  null as any,
);
