/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Domain
 */
export interface Domain {
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    name: string;
    /**
     * As part of a Stripe Subscription (or checkout session), to indicate the payment interval.
     * @type {string}
     * @memberof Domain
     */
    interval?: DomainIntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    status: DomainStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    type: DomainTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Domain
     */
    expires?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    renew?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    trackGA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    trackConversion?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Domain
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Domain
     */
    properties?: { [key: string]: string; };
}


/**
 * @export
 */
export const DomainIntervalEnum = {
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type DomainIntervalEnum = typeof DomainIntervalEnum[keyof typeof DomainIntervalEnum];

/**
 * @export
 */
export const DomainStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Suspended: 'SUSPENDED',
    Trialing: 'TRIALING'
} as const;
export type DomainStatusEnum = typeof DomainStatusEnum[keyof typeof DomainStatusEnum];

/**
 * @export
 */
export const DomainTypeEnum = {
    Free: 'FREE',
    Pro: 'PRO',
    Starter: 'STARTER'
} as const;
export type DomainTypeEnum = typeof DomainTypeEnum[keyof typeof DomainTypeEnum];


/**
 * Check if a given object implements the Domain interface.
 */
export function instanceOfDomain(value: object): value is Domain {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function DomainFromJSON(json: any): Domain {
    return DomainFromJSONTyped(json, false);
}

export function DomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domain {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': json['user'],
        'name': json['name'],
        'interval': json['interval'] == null ? undefined : json['interval'],
        'status': json['status'],
        'type': json['type'],
        'expires': json['expires'] == null ? undefined : (new Date(json['expires'])),
        'renew': json['renew'] == null ? undefined : json['renew'],
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'trackGA': json['trackGA'] == null ? undefined : json['trackGA'],
        'trackConversion': json['trackConversion'] == null ? undefined : json['trackConversion'],
        'aliases': json['aliases'] == null ? undefined : json['aliases'],
        'properties': json['properties'] == null ? undefined : json['properties'],
    };
}

export function DomainToJSON(value?: Domain | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user': value['user'],
        'name': value['name'],
        'interval': value['interval'],
        'status': value['status'],
        'type': value['type'],
        'expires': value['expires'] == null ? undefined : ((value['expires']).toISOString()),
        'renew': value['renew'],
        'timezone': value['timezone'],
        'trackGA': value['trackGA'],
        'trackConversion': value['trackConversion'],
        'aliases': value['aliases'],
        'properties': value['properties'],
    };
}

