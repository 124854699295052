/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActionSchedule } from './ActionSchedule';
import {
    ActionScheduleFromJSON,
    ActionScheduleFromJSONTyped,
    ActionScheduleToJSON,
} from './ActionSchedule';

/**
 * 
 * @export
 * @interface SettingsAction
 */
export interface SettingsAction {
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    actionType?: SettingsActionActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    actionValue?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SettingsAction
     */
    properties?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    backgroundColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsAction
     */
    iconEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    iconClass?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    iconColor?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    iconText?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    iconType?: SettingsActionIconTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    iconBackgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    labelBackgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsAction
     */
    labelText?: string;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof SettingsAction
     */
    schedule?: ActionSchedule;
}


/**
 * @export
 */
export const SettingsActionActionTypeEnum = {
    Anchor: 'ANCHOR',
    Email: 'EMAIL',
    Hours: 'HOURS',
    Link: 'LINK',
    Map: 'MAP',
    Phone: 'PHONE',
    Sms: 'SMS',
    Whatsapp: 'WHATSAPP',
    Facebook: 'FACEBOOK',
    Signal: 'SIGNAL',
    Telegram: 'TELEGRAM',
    Iframe: 'IFRAME',
    Tally: 'TALLY',
    Intercom: 'INTERCOM',
    Skype: 'SKYPE',
    Zalo: 'ZALO',
    Viber: 'VIBER',
    Line: 'LINE',
    Wechat: 'WECHAT',
    Chat: 'CHAT'
} as const;
export type SettingsActionActionTypeEnum = typeof SettingsActionActionTypeEnum[keyof typeof SettingsActionActionTypeEnum];

/**
 * @export
 */
export const SettingsActionIconTypeEnum = {
    Default: 'DEFAULT',
    Custom: 'CUSTOM',
    Font: 'FONT',
    Svg: 'SVG',
    FontMaterial: 'FONT_MATERIAL'
} as const;
export type SettingsActionIconTypeEnum = typeof SettingsActionIconTypeEnum[keyof typeof SettingsActionIconTypeEnum];


/**
 * Check if a given object implements the SettingsAction interface.
 */
export function instanceOfSettingsAction(value: object): value is SettingsAction {
    return true;
}

export function SettingsActionFromJSON(json: any): SettingsAction {
    return SettingsActionFromJSONTyped(json, false);
}

export function SettingsActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsAction {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'actionType': json['actionType'] == null ? undefined : json['actionType'],
        'actionValue': json['actionValue'] == null ? undefined : json['actionValue'],
        'properties': json['properties'] == null ? undefined : json['properties'],
        'backgroundColor': json['backgroundColor'] == null ? undefined : json['backgroundColor'],
        'iconEnabled': json['iconEnabled'] == null ? undefined : json['iconEnabled'],
        'iconClass': json['iconClass'] == null ? undefined : json['iconClass'],
        'iconColor': json['iconColor'] == null ? undefined : json['iconColor'],
        'iconText': json['iconText'] == null ? undefined : json['iconText'],
        'iconType': json['iconType'] == null ? undefined : json['iconType'],
        'iconBackgroundImage': json['iconBackgroundImage'] == null ? undefined : json['iconBackgroundImage'],
        'labelBackgroundColor': json['labelBackgroundColor'] == null ? undefined : json['labelBackgroundColor'],
        'labelText': json['labelText'] == null ? undefined : json['labelText'],
        'schedule': json['schedule'] == null ? undefined : ActionScheduleFromJSON(json['schedule']),
    };
}

export function SettingsActionToJSON(value?: SettingsAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'actionType': value['actionType'],
        'actionValue': value['actionValue'],
        'properties': value['properties'],
        'backgroundColor': value['backgroundColor'],
        'iconEnabled': value['iconEnabled'],
        'iconClass': value['iconClass'],
        'iconColor': value['iconColor'],
        'iconText': value['iconText'],
        'iconType': value['iconType'],
        'iconBackgroundImage': value['iconBackgroundImage'],
        'labelBackgroundColor': value['labelBackgroundColor'],
        'labelText': value['labelText'],
        'schedule': ActionScheduleToJSON(value['schedule']),
    };
}

