/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopyButtonRequest
 */
export interface CopyButtonRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CopyButtonRequest
     */
    sourceDomainIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CopyButtonRequest
     */
    sourceDomainPropertiesId?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyButtonRequest
     */
    targetDomainId: string;
}

/**
 * Check if a given object implements the CopyButtonRequest interface.
 */
export function instanceOfCopyButtonRequest(value: object): value is CopyButtonRequest {
    if (!('sourceDomainIds' in value) || value['sourceDomainIds'] === undefined) return false;
    if (!('targetDomainId' in value) || value['targetDomainId'] === undefined) return false;
    return true;
}

export function CopyButtonRequestFromJSON(json: any): CopyButtonRequest {
    return CopyButtonRequestFromJSONTyped(json, false);
}

export function CopyButtonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyButtonRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceDomainIds': json['sourceDomainIds'],
        'sourceDomainPropertiesId': json['sourceDomainPropertiesId'] == null ? undefined : json['sourceDomainPropertiesId'],
        'targetDomainId': json['targetDomainId'],
    };
}

export function CopyButtonRequestToJSON(value?: CopyButtonRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sourceDomainIds': value['sourceDomainIds'],
        'sourceDomainPropertiesId': value['sourceDomainPropertiesId'],
        'targetDomainId': value['targetDomainId'],
    };
}

