/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScrollOptions
 */
export interface ScrollOptions {
    /**
     * 
     * @type {number}
     * @memberof ScrollOptions
     */
    revealAtHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof ScrollOptions
     */
    hideAtHeight?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScrollOptions
     */
    neverHide?: boolean;
}

/**
 * Check if a given object implements the ScrollOptions interface.
 */
export function instanceOfScrollOptions(value: object): value is ScrollOptions {
    return true;
}

export function ScrollOptionsFromJSON(json: any): ScrollOptions {
    return ScrollOptionsFromJSONTyped(json, false);
}

export function ScrollOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScrollOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'revealAtHeight': json['revealAtHeight'] == null ? undefined : json['revealAtHeight'],
        'hideAtHeight': json['hideAtHeight'] == null ? undefined : json['hideAtHeight'],
        'neverHide': json['neverHide'] == null ? undefined : json['neverHide'],
    };
}

export function ScrollOptionsToJSON(value?: ScrollOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'revealAtHeight': value['revealAtHeight'],
        'hideAtHeight': value['hideAtHeight'],
        'neverHide': value['neverHide'],
    };
}

