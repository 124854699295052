import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "tss-react/mui";
import DomainIcon from "@mui/icons-material/Language";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import React from "react";
import { SpinnerContext } from "../../common";
import { DomainContext } from "../useDomain";
import { DomainType } from "../domainModels";
import { Domain } from "../../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& td": {
      color: theme.palette.text.secondary,
      borderBottomWidth: 0,
    },
    "& td:first-of-type": {
      paddingLeft: 0,
    },
    "&& td:last-child": {
      paddingRight: 0,
    },
  },
  nameCell: {
    overflowWrap: "anywhere",
  },
}));

/**
 * Shows a list of Domains, including a "Delete" icon with a confirmation box (using a Spinner).
 *
 * Requires at least the <code>SpinnerWrapper</code> and <code>DomainContext</code> in order to render.
 */
const DomainList: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { classes, cx } = useStyles();
  const { domains, deleteDomain } = React.useContext(DomainContext);
  const { withSpinner } = React.useContext(SpinnerContext);
  const [selected, setSelected] = React.useState<Domain | null>(null);
  const [confirmation, setConfirmation] = React.useState("");
  const handleDelete = (domain: Domain) => () => setSelected(domain);
  const handleCancel = (_: any, reason?: string) => {
    if (reason === "backdropClick") return;
    setSelected(null);
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (selected && confirmation === selected.name) {
      setSelected(null);
      setConfirmation("");
      await withSpinner(deleteDomain(selected.id));
    }
  };
  const handleConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmation(event.target.value);

  return (
    <>
      <Table size="small" className={cx(classes.root, className)}>
        <TableBody>
          {Object.values(domains || {}).map((domain) => (
            <TableRow key={domain.id}>
              <TableCell padding="checkbox">
                <DomainIcon />
              </TableCell>
              <TableCell className={classes.nameCell}>{domain.name}</TableCell>
              <TableCell>{DomainType.TYPE_NAME[domain.type]}</TableCell>
              <TableCell padding="checkbox">
                <Tooltip
                  title={
                    domain.type === "PRO"
                      ? "This domain has an active subscription and cannot be deleted. Please contact support@nowbuttons.com"
                      : "Delete"
                  }
                >
                  <span>
                    <IconButton
                      size="large"
                      onClick={handleDelete(domain)}
                      disabled={domain.type === "PRO"}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        open={!!selected}
        onClose={handleCancel}
        PaperProps={{ component: "form", onSubmit: handleSubmit } as any}
      >
        <DialogTitle>You are about to delete a domain!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting the domain also deletes all buttons on this domain. This
            action is permanent and cannot be undone!
          </DialogContentText>
          <DialogContentText>
            Enter the domain name <code>{selected?.name}</code> below to confirm
            the deletion:
          </DialogContentText>
          <TextField
            autoFocus
            label="Domain"
            fullWidth
            value={confirmation}
            onChange={handleConfirmChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={confirmation !== selected?.name}
            type="submit"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DomainList;
