import { createContext, useEffect, useMemo, useState } from "react";
import { default as prodApi, demoApi } from "./buttonApi";
import { Button } from "../lib/cnb/api/public";
import { ConditionType } from "./buttonModels";
import { isEqual } from "lodash";

const api = process.env.NODE_ENV === "development" ? demoApi : prodApi;

export const useButton = () => {
  const [buttons, setButtons] = useState<Record<string, Button> | undefined>();

  useEffect(() => {
    const fetchButtons = async () => {
      const bs = Object.fromEntries(
        (await api.getButtons()).map((b) => [b.id, b]),
      );
      setButtons(bs);
    };
    fetchButtons();
  }, []);

  const createButton = async (values: Button) => {
    const newActions = await Promise.all(
      values.actions.map((a) => api.createAction(a)),
    );
    const newConditions = await Promise.all(
      values.conditions
        .filter((c) => !isEqual(c, ConditionType.INITIAL))
        .map((c) => api.createCondition(c)),
    );
    const newButton = await api.createButton({
      ...values,
      domain: values.domain.id,
      actions: newActions.map((a) => a.id),
      conditions: newConditions.map((c) => c.id),
    });

    setButtons((bs) => ({ ...bs, [newButton.id]: newButton }));
    return newButton;
  };

  const updateButton = async (values: Button) => {
    const updatedActions = await Promise.all(
      values.actions.map((a) =>
        !a.id ? api.createAction(a) : api.updateAction(a),
      ),
    );
    const updatedConditions = await Promise.all(
      values.conditions
        .filter((c) => !isEqual(c, ConditionType.INITIAL))
        .map((c) => (!c.id ? api.createCondition(c) : api.updateCondition(c))),
    );
    const updatedButton = await api.updateButton({
      ...values,
      domain: values.domain.id,
      actions: updatedActions.map((a) => a.id),
      conditions: updatedConditions.map((c) => c.id),
    });

    setButtons((bs) => ({ ...bs, [updatedButton.id]: updatedButton }));
    return updatedButton;
  };

  const deleteButton = async (id: string) => {
    await api.deleteButton(id);

    setButtons((bs) => {
      const { [id]: _, ...updatedBs } = bs!;
      return updatedBs;
    });
  };

  const activateButton = async (id: string, active: boolean) => {
    const updatedButton = await api.updateButton({
      ...buttons![id],
      active,
      domain: buttons![id].domain.id,
      actions: buttons![id].actions.map((a) => a.id),
      conditions: buttons![id].conditions.map((c) => c.id),
    });
    setButtons((bs) => ({
      ...bs,
      [updatedButton.id]: updatedButton,
    }));
  };

  return useMemo(
    () => ({
      buttons,
      createButton,
      updateButton,
      deleteButton,
      activateButton,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttons],
  );
};

export const ButtonContext = createContext<ReturnType<typeof useButton>>(
  null as any,
);
