import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { alpha, lighten } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../common";
import { DomainContext } from "../../domain";
import logoShort from "../../assets/nowbuttons-logo-stacked.png";
import logo from "../../assets/nowbuttons-logo.png";
import { Box } from "@mui/material";
import OutstandingPaymentNote from "../../domain/components/OutstandingPaymentNote";
import UpgradeButton from "./UpgradeButton";
import { DomainType } from "../../domain/domainModels";

const useStyles = makeStyles()((theme) => ({
  logo: {
    justifyContent: "center",
    alignItems: "center",
    display: "inline-flex",
    img: {
      height: 38,
    },
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  domainButton: {
    color: "inherit",
    textTransform: "initial",
    marginLeft: theme.spacing(2),
    paddingRight: 0,
    backgroundColor: theme.palette.primary.light,
    "&:hover": { backgroundColor: lighten(theme.palette.primary.light, 0.1) },
  },
  domainType: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    marginLeft: "0.5em",
    borderRadius: theme.shape.borderRadius,
    lineHeight: 1,
    fontSize: "0.8em",
  },
  domainSettingsIcon: {
    marginLeft: -4,
    marginRight: 4,
  },
  addDomainIcon: {
    marginLeft: theme.spacing(-0.5),
  },
  headerButton: {
    marginLeft: theme.spacing(2),
    color: alpha(theme.palette.primary.contrastText, 0.8),
    "&:hover": { color: theme.palette.primary.contrastText },
    borderColor: "currentColor",
  },
  headerButtonBox: {
    marginLeft: theme.spacing(2),
  },
  headerIconButton: {
    padding: theme.spacing(0.5),
  },
  headerMenu: {
    marginTop: theme.spacing(6),
  },
  desktopOnly: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Header: React.FC = () => {
  const [domainMenu, setDomainMenu] = React.useState<null | HTMLElement>(null);
  const { domains, currentDomain, setCurrentDomain } =
    React.useContext(DomainContext);
  const { classes, cx } = useStyles();

  return (
    <>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box className={cx(classes.logo, classes.desktopOnly)}>
            <img src={logo} alt="logo" />
          </Box>
          <Box className={cx(classes.logo, classes.mobileOnly)}>
            <img src={logoShort} alt="logo" />
          </Box>
          <OutstandingPaymentNote />
          {currentDomain && (
            <>
              <Button
                variant="contained"
                className={classes.domainButton}
                onClick={(event) => setDomainMenu(event.currentTarget)}
              >
                {currentDomain.name}
                <span className={classes.domainType}>
                  {DomainType.TYPE_NAME[currentDomain.type]}
                </span>
                <ArrowDropDownIcon />
              </Button>
              <IconButton
                component={RouterLink}
                to={routes.DOMAIN_EDIT.path}
                color="inherit"
                className={cx(
                  classes.headerButton,
                  classes.headerIconButton,
                  classes.desktopOnly,
                )}
                size="large"
              >
                <SettingsIcon />
              </IconButton>
              <Box className={classes.headerButtonBox}>
                <UpgradeButton />
              </Box>
            </>
          )}
          <div className={classes.grow} />
          <IconButton
            component={RouterLink}
            to={routes.PROFILE.path}
            color="inherit"
            className={cx(classes.headerButton, classes.headerIconButton)}
            size="large"
          >
            <AccountIcon />
          </IconButton>
          <Menu
            open={Boolean(domainMenu)}
            anchorEl={domainMenu}
            onClose={() => setDomainMenu(null)}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            <MenuItem
              component={RouterLink}
              to={routes.DOMAIN_EDIT.path}
              className={classes.mobileOnly}
              onClick={() => setDomainMenu(null)}
            >
              <SettingsIcon className={classes.domainSettingsIcon} />
              Manage
            </MenuItem>
            <Divider className={classes.mobileOnly} />
            {Object.values(domains || {}).map((domain) => (
              <MenuItem
                key={domain.id}
                onClick={() => {
                  setCurrentDomain(domain.id);
                  setDomainMenu(null);
                }}
              >
                {domain.name}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              component={RouterLink}
              to={routes.DOMAIN_NEW.path}
              onClick={() => setDomainMenu(null)}
            >
              <AddIcon className={classes.addDomainIcon} />
              Add domain
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
