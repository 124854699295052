/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Settings options for the user.
 * @export
 * @interface SettingsOptions
 */
export interface SettingsOptions {
    /**
     * 
     * @type {string}
     * @memberof SettingsOptions
     */
    cssLocation: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsOptions
     */
    jsLocation: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsOptions
     */
    staticRoot: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsOptions
     */
    userRoot: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsOptions
     */
    apiRoot: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsOptions
     */
    storageType: SettingsOptionsStorageTypeEnum;
}


/**
 * @export
 */
export const SettingsOptionsStorageTypeEnum = {
    Gcs: 'GCS',
    R2: 'R2'
} as const;
export type SettingsOptionsStorageTypeEnum = typeof SettingsOptionsStorageTypeEnum[keyof typeof SettingsOptionsStorageTypeEnum];


/**
 * Check if a given object implements the SettingsOptions interface.
 */
export function instanceOfSettingsOptions(value: object): value is SettingsOptions {
    if (!('cssLocation' in value) || value['cssLocation'] === undefined) return false;
    if (!('jsLocation' in value) || value['jsLocation'] === undefined) return false;
    if (!('staticRoot' in value) || value['staticRoot'] === undefined) return false;
    if (!('userRoot' in value) || value['userRoot'] === undefined) return false;
    if (!('apiRoot' in value) || value['apiRoot'] === undefined) return false;
    if (!('storageType' in value) || value['storageType'] === undefined) return false;
    return true;
}

export function SettingsOptionsFromJSON(json: any): SettingsOptions {
    return SettingsOptionsFromJSONTyped(json, false);
}

export function SettingsOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'cssLocation': json['cssLocation'],
        'jsLocation': json['jsLocation'],
        'staticRoot': json['staticRoot'],
        'userRoot': json['userRoot'],
        'apiRoot': json['apiRoot'],
        'storageType': json['storageType'],
    };
}

export function SettingsOptionsToJSON(value?: SettingsOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cssLocation': value['cssLocation'],
        'jsLocation': value['jsLocation'],
        'staticRoot': value['staticRoot'],
        'userRoot': value['userRoot'],
        'apiRoot': value['apiRoot'],
        'storageType': value['storageType'],
    };
}

