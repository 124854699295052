/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    signupSource?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateRequest
     */
    emailOptIn?: boolean;
}

/**
 * Check if a given object implements the UserCreateRequest interface.
 */
export function instanceOfUserCreateRequest(value: object): value is UserCreateRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function UserCreateRequestFromJSON(json: any): UserCreateRequest {
    return UserCreateRequestFromJSONTyped(json, false);
}

export function UserCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'email': json['email'],
        'password': json['password'] == null ? undefined : json['password'],
        'signupSource': json['signupSource'] == null ? undefined : json['signupSource'],
        'emailOptIn': json['emailOptIn'] == null ? undefined : json['emailOptIn'],
    };
}

export function UserCreateRequestToJSON(value?: UserCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'email': value['email'],
        'password': value['password'],
        'signupSource': value['signupSource'],
        'emailOptIn': value['emailOptIn'],
    };
}

