/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.195-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  ButtonMinimal,
  Condition,
  ConditionCreateRequest,
  ConditionDeleteResponse,
  ConditionUpdateRequest,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    ButtonMinimalFromJSON,
    ButtonMinimalToJSON,
    ConditionFromJSON,
    ConditionToJSON,
    ConditionCreateRequestFromJSON,
    ConditionCreateRequestToJSON,
    ConditionDeleteResponseFromJSON,
    ConditionDeleteResponseToJSON,
    ConditionUpdateRequestFromJSON,
    ConditionUpdateRequestToJSON,
} from '../models/index';

export interface ConditionApiCreateRequest {
    conditionCreateRequest: ConditionCreateRequest;
}

export interface ConditionApiDeleteRequest {
    conditionId: string;
}

export interface ConditionApiGetRequest {
    conditionId: string;
}

export interface ConditionApiGetButtonsRequest {
    conditionId: string;
}

export interface ConditionApiUpdateRequest {
    conditionId: string;
    conditionUpdateRequest: ConditionUpdateRequest;
}

/**
 * 
 */
export class ConditionApi extends runtime.BaseAPI {

    /**
     * Create a new `Condition`.
     */
    async createRaw(requestParameters: ConditionApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Condition>> {
        if (requestParameters['conditionCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'conditionCreateRequest',
                'Required parameter "conditionCreateRequest" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/condition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionCreateRequestToJSON(requestParameters['conditionCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionFromJSON(jsonValue));
    }

    /**
     * Create a new `Condition`.
     */
    async create(requestParameters: ConditionApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Condition> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a `Condition`.
     */
    async deleteRaw(requestParameters: ConditionApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConditionDeleteResponse>> {
        if (requestParameters['conditionId'] == null) {
            throw new runtime.RequiredError(
                'conditionId',
                'Required parameter "conditionId" was null or undefined when calling delete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/condition/{conditionId}`.replace(`{${"conditionId"}}`, encodeURIComponent(String(requestParameters['conditionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete a `Condition`.
     */
    async delete(requestParameters: ConditionApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConditionDeleteResponse> {
        const response = await this.deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a `Condition` by its ID
     */
    async getRaw(requestParameters: ConditionApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Condition>> {
        if (requestParameters['conditionId'] == null) {
            throw new runtime.RequiredError(
                'conditionId',
                'Required parameter "conditionId" was null or undefined when calling get().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/condition/{conditionId}`.replace(`{${"conditionId"}}`, encodeURIComponent(String(requestParameters['conditionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionFromJSON(jsonValue));
    }

    /**
     * Get a `Condition` by its ID
     */
    async get(requestParameters: ConditionApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Condition> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all `Condition`s for this User
     */
    async getAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Condition>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/condition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConditionFromJSON));
    }

    /**
     * Gets all `Condition`s for this User
     */
    async getAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Condition>> {
        const response = await this.getAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the `Button`s associated with this `Condition`
     */
    async getButtonsRaw(requestParameters: ConditionApiGetButtonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ButtonMinimal>>> {
        if (requestParameters['conditionId'] == null) {
            throw new runtime.RequiredError(
                'conditionId',
                'Required parameter "conditionId" was null or undefined when calling getButtons().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/condition/button/{conditionId}`.replace(`{${"conditionId"}}`, encodeURIComponent(String(requestParameters['conditionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ButtonMinimalFromJSON));
    }

    /**
     * Get the `Button`s associated with this `Condition`
     */
    async getButtons(requestParameters: ConditionApiGetButtonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ButtonMinimal>> {
        const response = await this.getButtonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a `Condition`.
     */
    async updateRaw(requestParameters: ConditionApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Condition>> {
        if (requestParameters['conditionId'] == null) {
            throw new runtime.RequiredError(
                'conditionId',
                'Required parameter "conditionId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['conditionUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'conditionUpdateRequest',
                'Required parameter "conditionUpdateRequest" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/condition/{conditionId}`.replace(`{${"conditionId"}}`, encodeURIComponent(String(requestParameters['conditionId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionUpdateRequestToJSON(requestParameters['conditionUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionFromJSON(jsonValue));
    }

    /**
     * Update a `Condition`.
     */
    async update(requestParameters: ConditionApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Condition> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
