import React from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const GaWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    const isDev = process.env.NODE_ENV === "development";
    ReactGA.initialize(process.env.REACT_APP_GA!, { testMode: isDev });
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: currentPath });
  }, [location]);

  return <>{children}</>;
};

export default GaWrapper;
