import React from "react";
import { default as prodApi, demoApi } from "./domainApi";
import { StripePlanType } from "./domainModels";
import useWpInfo from "../common/useWpInfo";
import useSettings from "../common/settings/useSettings";
import { Domain, StripePlan } from "../lib/cnb/api/public";

const api = process.env.NODE_ENV === "development" ? demoApi : prodApi;

/**
 * @param {string} urlSearch possible query parameters:
 * - ``: there was no callback, but a normal browser navigation
 * - `?payment=cancelled`: Stripe payment was cancelled / error
 * - `?payment=success&checkout_session_id={CHECKOUT_SESSION_ID}`: payment was successful or still pending
 */
export const useDomain = (urlSearch: string) => {
  const [domains, setDomains] = React.useState<
    Record<string, Domain> | undefined
  >(undefined);
  const [currentDomainId, setCurrentDomainId] = React.useState<string | null>(
    api.getCurrentDomainId(),
  );
  const [stripePlans, setStripePlans] = React.useState<
    StripePlan[] | undefined
  >(undefined);
  const timeoutId = React.useRef<number | undefined>();
  const [paymentResult, setPaymentResult] = React.useState<
    | {
        status: "cancelled" | null;
      }
    | { status: "success" | "pending"; domainId: string }
  >({ status: null });

  const currentDomain = currentDomainId
    ? domains?.[currentDomainId]
    : undefined;
  const wpInfo = useWpInfo(currentDomain);
  const settings = useSettings();

  React.useEffect(() => {
    const fetchDomains = async () => {
      const ds = Object.fromEntries(
        (await api.getDomains()).map((d) => [d.id, d]),
      );
      if (!(currentDomainId && ds[currentDomainId])) {
        setCurrentDomain(Object.keys(ds)[0]);
      }
      // domains have to be the last, determines if data loaded
      setDomains(ds);
    };
    fetchDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentResult]);

  React.useEffect(() => {
    const fetchPlans = async () => {
      const plans = (await api.getStripePlans()).filter((p) =>
        p.nickname.startsWith(StripePlanType.NICKNAME_PREFIX),
      );
      setStripePlans(plans);
    };
    fetchPlans();
  }, []);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(urlSearch);
    const checkoutSessionId = searchParams.get("checkout_session_id") || "";
    const rawResult = searchParams.get("payment");
    const result: any = rawResult === "success" ? "pending" : rawResult;

    const fetchPaymentSession = async () => {
      const sessionData = await api.getPaymentSession(checkoutSessionId);
      if (sessionData.subscriptionId) {
        // At this point, we do NOT know if the domain is already updated, so we
        // have to check for that as well
        const domain = (await api.getDomains()).find(
          (d) => d.id === sessionData.domain,
        );
        if (domain?.type !== "PRO") {
          setPaymentResult({ status: "pending", domainId: sessionData.domain });
          timeoutId.current = window.setTimeout(fetchPaymentSession, 1000);
        } else {
          setPaymentResult({ status: "success", domainId: sessionData.domain });
        }
      } else {
        timeoutId.current = window.setTimeout(fetchPaymentSession, 1000);
      }
    };

    if (result === "pending") {
      fetchPaymentSession();
    }
    if (paymentResult.status !== "pending") {
      setPaymentResult({ status: result });
    }

    return () => window.clearTimeout(timeoutId.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearch]);

  const createDomain = async (values: Partial<Domain>) => {
    const newDomain = await api.createDomain(values);
    setDomains((ds) => ({ ...ds, [newDomain.id]: newDomain }));
    return newDomain.id;
  };

  const updateDomain = async (values: Domain) => {
    const updatedDomain = await api.updateDomain(values);
    setDomains((ds) => ({ ...ds, [updatedDomain.id]: updatedDomain }));
  };

  const deleteDomain = async (id: string) => {
    await api.deleteDomain(id);
    const { [id]: _, ...updatedDomains } = domains!;
    if (id === currentDomainId) {
      setCurrentDomain(Object.keys(updatedDomains)[0]);
    }
    setDomains(updatedDomains);
  };

  const setCurrentDomain = (domainId: string) => {
    api.setCurrentDomainId(domainId);
    setCurrentDomainId(domainId);
  };

  const redirectToCheckout = async (
    interval: Domain["interval"],
    currency: StripePlan["currency"],
  ) => {
    const plan = stripePlans?.find(
      (p) => p.interval === interval && p.currency === currency,
    );
    if (!plan || !currentDomainId) throw new Error("Invalid data for checkout");

    await api.redirectToCheckout(plan.id, currentDomainId);
  };

  const getUserRoot = () => {
    return (
      settings.settings?.options.userRoot || process.env.REACT_APP_USER_URL
    );
  };

  const getClientUrl = (userOrDomainId: string) =>
    `${getUserRoot()}/${userOrDomainId}.js`;

  return React.useMemo(
    () => ({
      domains,
      currentDomain,
      stripePlans,
      paymentResult,
      setCurrentDomain,
      createDomain,
      updateDomain,
      deleteDomain,
      redirectToCheckout,
      getClientUrl,
      wpInfo,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [domains, currentDomainId, stripePlans, wpInfo],
  );
};

export const DomainContext = React.createContext<ReturnType<typeof useDomain>>(
  null as any,
);
